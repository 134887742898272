import Vue from "vue";
import VueI18n from "vue-i18n";
import az from "vee-validate/dist/locale/az.json";
import en from "vee-validate/dist/locale/en.json";
import ru from "vee-validate/dist/locale/ru.json";
import tr from "vee-validate/dist/locale/tr.json";



Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "az",
  messages: {
    az: {
      fields: {
        phone: 'telefon',
        email: "E-poçt",
        password: "Şifrə",
      },
      customValidations: {
        confirm: 'Şifrə təsdiqi uyğun gəlmir'
      },
      validations: az
    },
    tr: {
      fields: {
        phone: 'Telefon',
        email: "E-poçt",
        password: "Şifre",
      },
      customValidations: {
        confirm: 'Şifre onayı eşleşmiyor'
      },
      validations: tr
    },
    ru: {
      fields: {
        phone: 'Телефон',
        email: "Почта",
        password: "Пароль"
      },
      customValidations: {
        confirm: 'Подтверждение пароля не совпадает'
      },
      validations: ru
    },
    en: {
      fields: {
        phone: "phone",
        email: "E-mail",
        password: "Password"
      },
      customValidations: {
        confirm: 'Password confirmation does not match'
      },
      validations: en
    }
  }
});

export { i18n };

