
  import IconTwitter from '~/assets/icons/twitter.svg?inline';
  import IconFacebook from '~/assets/icons/facebook.svg?inline';
  import IconLinkedin from '~/assets/icons/linkedin.svg?inline';
  import IconYoutube from '~/assets/icons/youtube.svg?inline';

  export default {
    components: {
      IconTwitter,
      IconFacebook,
      IconLinkedin,
      IconYoutube,
    },
    data: () => ({
      loadingLang: false,
      currentYear: new Date().getFullYear(),
    // menu: null,
    }),
    computed: {
      menu() {
        return this.$store.state.navigation.items;
      },
    },
    mounted() {
      // setTimeout(() => {
      //   this.menu = this.$store.state.navigation.items;
      // }, 400)
      // console.log('menu', this.$store.state.navigation.items)
      // this.$nuxt.$on('lang-changed', () => {
      //   this.loadingLang = true;
      //   setTimeout(() => {
      //     this.loadingLang = false
      //     this.menu = this.$store.state.navigation.items;
      //   }, 50)
      // })
    },
  }

