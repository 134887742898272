export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"name":"Azərbaycan","code":"az","iso":"az-AZ","file":"az.js"},{"name":"Turkish","code":"tr","iso":"tr-TR","file":"tr.js"},{"name":"English","code":"en","iso":"en-GB","file":"en.js"},{"name":"Русский","code":"ru","iso":"ru-RUS","file":"ru.js"}],
  defaultLocale: "az",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/base/1146/public_html/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  loadLanguagesAsync: true,
  noPrefixDefaultLocale: true,
  normalizedLocales: [{"name":"Azərbaycan","code":"az","iso":"az-AZ","file":"az.js"},{"name":"Turkish","code":"tr","iso":"tr-TR","file":"tr.js"},{"name":"English","code":"en","iso":"en-GB","file":"en.js"},{"name":"Русский","code":"ru","iso":"ru-RUS","file":"ru.js"}],
  localeCodes: ["az","tr","en","ru"],
}

export const localeMessages = {
  'az.js': () => import('../../lang/az.js' /* webpackChunkName: "lang-az.js" */),
  'tr.js': () => import('../../lang/tr.js' /* webpackChunkName: "lang-tr.js" */),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
  'ru.js': () => import('../../lang/ru.js' /* webpackChunkName: "lang-ru.js" */),
}
