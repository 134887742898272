
  export default {
    data: () => ({
      menu: null,
      loadingLang: false
    }),
    async fetch() {
      const currentLocale = this.$nuxt.context.app.i18n.locales.filter((lang) => lang.code === this.$nuxt.context.app.i18n.locale)[0];
      const response = await fetch(this.$store.$globals.getUrl('/api/menu/' + currentLocale.code)).then(
        (res) => res.json()
      );
      this.menu = response.items;
      this.saveNav();
    },
    mounted() {
    this.$nuxt.$on('lang-changed', () => {
      this.loadingLang = true;
     this.getMenu().then(() => this.loadingLang = false);
    })
    },
    methods: {
      saveNav() {
        this.$store.commit("navigation/save", this.menu);
      },

      async getMenu() {
        const currentLocale = this.$nuxt.context.app.i18n.locales.filter((lang) => lang.code === this.$nuxt.context.app.i18n.locale)[0];
      const response = await fetch(this.$store.$globals.getUrl('/api/menu/' + currentLocale.code)).then(
        (res) => res.json()
      );
      this.menu = response.items;
      this.saveNav();
    },
    },
  };

