
  import IconChevron from '~/assets/icons/chevron-right.svg?inline';
  import IconInstagram from '~/assets/icons/instagram.svg?inline';
  import IconFacebook from '~/assets/icons/facebook.svg?inline';
  import IconLinkedin from '~/assets/icons/linkedin.svg?inline';
  import IconYoutube from '~/assets/icons/youtube.svg?inline';
  import IconTwitter from '~/assets/icons/twitter.svg?inline';
  import IconCall from '~/assets/icons/call.svg?inline';
  import IconClose from '~/assets/icons/close.svg?inline';
  import IconUser from '~/assets/icons/user.svg?inline';
  import IconSearch from '~/assets/icons/search.svg?inline';
  import IconLogout from '~/assets/icons/logout.svg?inline';

  export default {
    head() {
      return {
        bodyAttrs: {
          class: this.openNavigation || this.searchOpen ? 'overflow-hidden' : '',
          style: this.openNavigation || this.searchOpen ? 'padding-right:5px' : '',
        }
      }
    },
    props: {
      hideToggle: {
        type: Boolean,
        default: false
      },
      collapsed: {
        type: Boolean,
        default: true
      },
    },
    components: {
      IconChevron,
      IconInstagram,
      IconFacebook,
      IconLinkedin,
      IconYoutube,
      IconCall,
      IconUser,
      IconSearch,
      IconClose,
      IconLogout,
      IconTwitter,
    },
    data: () => ({
      index: null,
      Accordion: {
        count: 0,
        active: null
      },
      isLanguages: '',
      isNavigation: '',
      openNavigation: false,
      limitPosition: 100,
      scrolled: false,
      lastPosition: 0,
      isOpen: false,

      searchOpen: false

    }),
    computed: {
      visible() {
        return this.Accordion.active;
      },
      availableLocales() {
        return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);

      },
      currentLocales() {
        return this.$i18n.locales.filter(i => i.code == this.$i18n.locale)
      },
      settings() {
        return this.$store.state.settings.setting

      },
             menu() {
      return this.$store.state.navigation.items;
    },
    },
    created() {
      this.index = this.Accordion.count++;
    },
    mounted() {

      this.$nuxt.$on('updateSerachResult', () => {
        this.searchOpen = false;
      })
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    watch: {
      '$route': function () {
        this.searchOpen = false;
      },

    },
    methods: {
      touchStart(e) {
        this.searchOpen = false;
      },


      handleScroll() {
        if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
          this.scrolled = true;
          // move up!
        }

        if (this.lastPosition > window.scrollY) {
          this.scrolled = false;
          // move down
        }

        this.lastPosition = window.scrollY;
        // this.scrolled = window.scrollY > 250;
      },
      open(i) {

        if (this.visible == i) {
          this.Accordion.active = null;
        } else {
          this.Accordion.active = i;
        }

      },
      start(el) {
        el.style.height = el.scrollHeight + "px";
      },
      end(el) {
        el.style.height = "";
      }

    },
  };

