import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31b35104 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _9e134974 = () => interopDefault(import('../pages/announcements/index.vue' /* webpackChunkName: "pages/announcements/index" */))
const _9cf23ace = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _7c9ed1a1 = () => interopDefault(import('../pages/charters.vue' /* webpackChunkName: "pages/charters" */))
const _d5fd5a32 = () => interopDefault(import('../pages/chiefs.vue' /* webpackChunkName: "pages/chiefs" */))
const _d6c01752 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _516b2bc9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _20156e2e = () => interopDefault(import('../pages/enterprises.vue' /* webpackChunkName: "pages/enterprises" */))
const _74e0d066 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _1becab23 = () => interopDefault(import('../pages/farmer-calculator.vue' /* webpackChunkName: "pages/farmer-calculator" */))
const _cf476f3e = () => interopDefault(import('../pages/galleryImages.vue' /* webpackChunkName: "pages/galleryImages" */))
const _e4265d7e = () => interopDefault(import('../pages/galleryVideos.vue' /* webpackChunkName: "pages/galleryVideos" */))
const _4836804b = () => interopDefault(import('../pages/HomeNews.vue' /* webpackChunkName: "pages/HomeNews" */))
const _89f7ea68 = () => interopDefault(import('../pages/laboratory.vue' /* webpackChunkName: "pages/laboratory" */))
const _2871ea8b = () => interopDefault(import('../pages/legalisation.vue' /* webpackChunkName: "pages/legalisation" */))
const _1b12cee0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1573402f = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _5776f936 = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _4b0dcba8 = () => interopDefault(import('../pages/processing.vue' /* webpackChunkName: "pages/processing" */))
const _01534600 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _dc558f70 = () => interopDefault(import('../pages/registration-private.vue' /* webpackChunkName: "pages/registration-private" */))
const _1052293e = () => interopDefault(import('../pages/requestsandoffers.vue' /* webpackChunkName: "pages/requestsandoffers" */))
const _1a8189da = () => interopDefault(import('../pages/saving.vue' /* webpackChunkName: "pages/saving" */))
const _f08db27e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _32f41c84 = () => interopDefault(import('../pages/searchResult.vue' /* webpackChunkName: "pages/searchResult" */))
const _ac87e328 = () => interopDefault(import('../pages/seed-growing.vue' /* webpackChunkName: "pages/seed-growing" */))
const _0907d788 = () => interopDefault(import('../pages/statistics.vue' /* webpackChunkName: "pages/statistics" */))
const _0d539f2c = () => interopDefault(import('../pages/structure.vue' /* webpackChunkName: "pages/structure" */))
const _2ef084d3 = () => interopDefault(import('../pages/vacancies/index.vue' /* webpackChunkName: "pages/vacancies/index" */))
const _39c8034b = () => interopDefault(import('../pages/app/requests.vue' /* webpackChunkName: "pages/app/requests" */))
const _16f88d13 = () => interopDefault(import('../pages/products/search-results.vue' /* webpackChunkName: "pages/products/search-results" */))
const _25f2687c = () => interopDefault(import('../pages/user/account.vue' /* webpackChunkName: "pages/user/account" */))
const _ce6428e6 = () => interopDefault(import('../pages/user/documents.vue' /* webpackChunkName: "pages/user/documents" */))
const _55f2c9b4 = () => interopDefault(import('../pages/user/passwordChange.vue' /* webpackChunkName: "pages/user/passwordChange" */))
const _79dd6dbf = () => interopDefault(import('../pages/user/requestsandoffers.vue' /* webpackChunkName: "pages/user/requestsandoffers" */))
const _a1808c04 = () => interopDefault(import('../pages/announcements/_slug.vue' /* webpackChunkName: "pages/announcements/_slug" */))
const _a05f7d5e = () => interopDefault(import('../pages/articles/_slug.vue' /* webpackChunkName: "pages/articles/_slug" */))
const _13bc9ee7 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _04c08890 = () => interopDefault(import('../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _2d39e38b = () => interopDefault(import('../pages/vacancies/_slug.vue' /* webpackChunkName: "pages/vacancies/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _31b35104,
    name: "about___az"
  }, {
    path: "/announcements",
    component: _9e134974,
    name: "announcements___az"
  }, {
    path: "/articles",
    component: _9cf23ace,
    name: "articles___az"
  }, {
    path: "/charters",
    component: _7c9ed1a1,
    name: "charters___az"
  }, {
    path: "/chiefs",
    component: _d5fd5a32,
    name: "chiefs___az"
  }, {
    path: "/contact",
    component: _d6c01752,
    name: "contact___az"
  }, {
    path: "/en",
    component: _516b2bc9,
    name: "index___en"
  }, {
    path: "/enterprises",
    component: _20156e2e,
    name: "enterprises___az"
  }, {
    path: "/faq",
    component: _74e0d066,
    name: "faq___az"
  }, {
    path: "/farmer-calculator",
    component: _1becab23,
    name: "farmer-calculator___az"
  }, {
    path: "/galleryImages",
    component: _cf476f3e,
    name: "galleryImages___az"
  }, {
    path: "/galleryVideos",
    component: _e4265d7e,
    name: "galleryVideos___az"
  }, {
    path: "/HomeNews",
    component: _4836804b,
    name: "HomeNews___az"
  }, {
    path: "/laboratory",
    component: _89f7ea68,
    name: "laboratory___az"
  }, {
    path: "/legalisation",
    component: _2871ea8b,
    name: "legalisation___az"
  }, {
    path: "/login",
    component: _1b12cee0,
    name: "login___az"
  }, {
    path: "/news",
    component: _1573402f,
    name: "news___az"
  }, {
    path: "/password-reset",
    component: _5776f936,
    name: "password-reset___az"
  }, {
    path: "/processing",
    component: _4b0dcba8,
    name: "processing___az"
  }, {
    path: "/products",
    component: _01534600,
    name: "products___az"
  }, {
    path: "/registration-private",
    component: _dc558f70,
    name: "registration-private___az"
  }, {
    path: "/requestsandoffers",
    component: _1052293e,
    name: "requestsandoffers___az"
  }, {
    path: "/ru",
    component: _516b2bc9,
    name: "index___ru"
  }, {
    path: "/saving",
    component: _1a8189da,
    name: "saving___az"
  }, {
    path: "/search",
    component: _f08db27e,
    name: "search___az"
  }, {
    path: "/searchResult",
    component: _32f41c84,
    name: "searchResult___az"
  }, {
    path: "/seed-growing",
    component: _ac87e328,
    name: "seed-growing___az"
  }, {
    path: "/statistics",
    component: _0907d788,
    name: "statistics___az"
  }, {
    path: "/structure",
    component: _0d539f2c,
    name: "structure___az"
  }, {
    path: "/tr",
    component: _516b2bc9,
    name: "index___tr"
  }, {
    path: "/vacancies",
    component: _2ef084d3,
    name: "vacancies___az"
  }, {
    path: "/app/requests",
    component: _39c8034b,
    name: "app-requests___az"
  }, {
    path: "/en/about",
    component: _31b35104,
    name: "about___en"
  }, {
    path: "/en/announcements",
    component: _9e134974,
    name: "announcements___en"
  }, {
    path: "/en/articles",
    component: _9cf23ace,
    name: "articles___en"
  }, {
    path: "/en/charters",
    component: _7c9ed1a1,
    name: "charters___en"
  }, {
    path: "/en/chiefs",
    component: _d5fd5a32,
    name: "chiefs___en"
  }, {
    path: "/en/contact",
    component: _d6c01752,
    name: "contact___en"
  }, {
    path: "/en/enterprises",
    component: _20156e2e,
    name: "enterprises___en"
  }, {
    path: "/en/faq",
    component: _74e0d066,
    name: "faq___en"
  }, {
    path: "/en/farmer-calculator",
    component: _1becab23,
    name: "farmer-calculator___en"
  }, {
    path: "/en/galleryImages",
    component: _cf476f3e,
    name: "galleryImages___en"
  }, {
    path: "/en/galleryVideos",
    component: _e4265d7e,
    name: "galleryVideos___en"
  }, {
    path: "/en/HomeNews",
    component: _4836804b,
    name: "HomeNews___en"
  }, {
    path: "/en/laboratory",
    component: _89f7ea68,
    name: "laboratory___en"
  }, {
    path: "/en/legalisation",
    component: _2871ea8b,
    name: "legalisation___en"
  }, {
    path: "/en/login",
    component: _1b12cee0,
    name: "login___en"
  }, {
    path: "/en/news",
    component: _1573402f,
    name: "news___en"
  }, {
    path: "/en/password-reset",
    component: _5776f936,
    name: "password-reset___en"
  }, {
    path: "/en/processing",
    component: _4b0dcba8,
    name: "processing___en"
  }, {
    path: "/en/products",
    component: _01534600,
    name: "products___en"
  }, {
    path: "/en/registration-private",
    component: _dc558f70,
    name: "registration-private___en"
  }, {
    path: "/en/requestsandoffers",
    component: _1052293e,
    name: "requestsandoffers___en"
  }, {
    path: "/en/saving",
    component: _1a8189da,
    name: "saving___en"
  }, {
    path: "/en/search",
    component: _f08db27e,
    name: "search___en"
  }, {
    path: "/en/searchResult",
    component: _32f41c84,
    name: "searchResult___en"
  }, {
    path: "/en/seed-growing",
    component: _ac87e328,
    name: "seed-growing___en"
  }, {
    path: "/en/statistics",
    component: _0907d788,
    name: "statistics___en"
  }, {
    path: "/en/structure",
    component: _0d539f2c,
    name: "structure___en"
  }, {
    path: "/en/vacancies",
    component: _2ef084d3,
    name: "vacancies___en"
  }, {
    path: "/products/search-results",
    component: _16f88d13,
    name: "products-search-results___az"
  }, {
    path: "/ru/about",
    component: _31b35104,
    name: "about___ru"
  }, {
    path: "/ru/announcements",
    component: _9e134974,
    name: "announcements___ru"
  }, {
    path: "/ru/articles",
    component: _9cf23ace,
    name: "articles___ru"
  }, {
    path: "/ru/charters",
    component: _7c9ed1a1,
    name: "charters___ru"
  }, {
    path: "/ru/chiefs",
    component: _d5fd5a32,
    name: "chiefs___ru"
  }, {
    path: "/ru/contact",
    component: _d6c01752,
    name: "contact___ru"
  }, {
    path: "/ru/enterprises",
    component: _20156e2e,
    name: "enterprises___ru"
  }, {
    path: "/ru/faq",
    component: _74e0d066,
    name: "faq___ru"
  }, {
    path: "/ru/farmer-calculator",
    component: _1becab23,
    name: "farmer-calculator___ru"
  }, {
    path: "/ru/galleryImages",
    component: _cf476f3e,
    name: "galleryImages___ru"
  }, {
    path: "/ru/galleryVideos",
    component: _e4265d7e,
    name: "galleryVideos___ru"
  }, {
    path: "/ru/HomeNews",
    component: _4836804b,
    name: "HomeNews___ru"
  }, {
    path: "/ru/laboratory",
    component: _89f7ea68,
    name: "laboratory___ru"
  }, {
    path: "/ru/legalisation",
    component: _2871ea8b,
    name: "legalisation___ru"
  }, {
    path: "/ru/login",
    component: _1b12cee0,
    name: "login___ru"
  }, {
    path: "/ru/news",
    component: _1573402f,
    name: "news___ru"
  }, {
    path: "/ru/password-reset",
    component: _5776f936,
    name: "password-reset___ru"
  }, {
    path: "/ru/processing",
    component: _4b0dcba8,
    name: "processing___ru"
  }, {
    path: "/ru/products",
    component: _01534600,
    name: "products___ru"
  }, {
    path: "/ru/registration-private",
    component: _dc558f70,
    name: "registration-private___ru"
  }, {
    path: "/ru/requestsandoffers",
    component: _1052293e,
    name: "requestsandoffers___ru"
  }, {
    path: "/ru/saving",
    component: _1a8189da,
    name: "saving___ru"
  }, {
    path: "/ru/search",
    component: _f08db27e,
    name: "search___ru"
  }, {
    path: "/ru/searchResult",
    component: _32f41c84,
    name: "searchResult___ru"
  }, {
    path: "/ru/seed-growing",
    component: _ac87e328,
    name: "seed-growing___ru"
  }, {
    path: "/ru/statistics",
    component: _0907d788,
    name: "statistics___ru"
  }, {
    path: "/ru/structure",
    component: _0d539f2c,
    name: "structure___ru"
  }, {
    path: "/ru/vacancies",
    component: _2ef084d3,
    name: "vacancies___ru"
  }, {
    path: "/tr/about",
    component: _31b35104,
    name: "about___tr"
  }, {
    path: "/tr/announcements",
    component: _9e134974,
    name: "announcements___tr"
  }, {
    path: "/tr/articles",
    component: _9cf23ace,
    name: "articles___tr"
  }, {
    path: "/tr/charters",
    component: _7c9ed1a1,
    name: "charters___tr"
  }, {
    path: "/tr/chiefs",
    component: _d5fd5a32,
    name: "chiefs___tr"
  }, {
    path: "/tr/contact",
    component: _d6c01752,
    name: "contact___tr"
  }, {
    path: "/tr/enterprises",
    component: _20156e2e,
    name: "enterprises___tr"
  }, {
    path: "/tr/faq",
    component: _74e0d066,
    name: "faq___tr"
  }, {
    path: "/tr/farmer-calculator",
    component: _1becab23,
    name: "farmer-calculator___tr"
  }, {
    path: "/tr/galleryImages",
    component: _cf476f3e,
    name: "galleryImages___tr"
  }, {
    path: "/tr/galleryVideos",
    component: _e4265d7e,
    name: "galleryVideos___tr"
  }, {
    path: "/tr/HomeNews",
    component: _4836804b,
    name: "HomeNews___tr"
  }, {
    path: "/tr/laboratory",
    component: _89f7ea68,
    name: "laboratory___tr"
  }, {
    path: "/tr/legalisation",
    component: _2871ea8b,
    name: "legalisation___tr"
  }, {
    path: "/tr/login",
    component: _1b12cee0,
    name: "login___tr"
  }, {
    path: "/tr/news",
    component: _1573402f,
    name: "news___tr"
  }, {
    path: "/tr/password-reset",
    component: _5776f936,
    name: "password-reset___tr"
  }, {
    path: "/tr/processing",
    component: _4b0dcba8,
    name: "processing___tr"
  }, {
    path: "/tr/products",
    component: _01534600,
    name: "products___tr"
  }, {
    path: "/tr/registration-private",
    component: _dc558f70,
    name: "registration-private___tr"
  }, {
    path: "/tr/requestsandoffers",
    component: _1052293e,
    name: "requestsandoffers___tr"
  }, {
    path: "/tr/saving",
    component: _1a8189da,
    name: "saving___tr"
  }, {
    path: "/tr/search",
    component: _f08db27e,
    name: "search___tr"
  }, {
    path: "/tr/searchResult",
    component: _32f41c84,
    name: "searchResult___tr"
  }, {
    path: "/tr/seed-growing",
    component: _ac87e328,
    name: "seed-growing___tr"
  }, {
    path: "/tr/statistics",
    component: _0907d788,
    name: "statistics___tr"
  }, {
    path: "/tr/structure",
    component: _0d539f2c,
    name: "structure___tr"
  }, {
    path: "/tr/vacancies",
    component: _2ef084d3,
    name: "vacancies___tr"
  }, {
    path: "/user/account",
    component: _25f2687c,
    name: "user-account___az"
  }, {
    path: "/user/documents",
    component: _ce6428e6,
    name: "user-documents___az"
  }, {
    path: "/user/passwordChange",
    component: _55f2c9b4,
    name: "user-passwordChange___az"
  }, {
    path: "/user/requestsandoffers",
    component: _79dd6dbf,
    name: "user-requestsandoffers___az"
  }, {
    path: "/en/app/requests",
    component: _39c8034b,
    name: "app-requests___en"
  }, {
    path: "/en/products/search-results",
    component: _16f88d13,
    name: "products-search-results___en"
  }, {
    path: "/en/user/account",
    component: _25f2687c,
    name: "user-account___en"
  }, {
    path: "/en/user/documents",
    component: _ce6428e6,
    name: "user-documents___en"
  }, {
    path: "/en/user/passwordChange",
    component: _55f2c9b4,
    name: "user-passwordChange___en"
  }, {
    path: "/en/user/requestsandoffers",
    component: _79dd6dbf,
    name: "user-requestsandoffers___en"
  }, {
    path: "/ru/app/requests",
    component: _39c8034b,
    name: "app-requests___ru"
  }, {
    path: "/ru/products/search-results",
    component: _16f88d13,
    name: "products-search-results___ru"
  }, {
    path: "/ru/user/account",
    component: _25f2687c,
    name: "user-account___ru"
  }, {
    path: "/ru/user/documents",
    component: _ce6428e6,
    name: "user-documents___ru"
  }, {
    path: "/ru/user/passwordChange",
    component: _55f2c9b4,
    name: "user-passwordChange___ru"
  }, {
    path: "/ru/user/requestsandoffers",
    component: _79dd6dbf,
    name: "user-requestsandoffers___ru"
  }, {
    path: "/tr/app/requests",
    component: _39c8034b,
    name: "app-requests___tr"
  }, {
    path: "/tr/products/search-results",
    component: _16f88d13,
    name: "products-search-results___tr"
  }, {
    path: "/tr/user/account",
    component: _25f2687c,
    name: "user-account___tr"
  }, {
    path: "/tr/user/documents",
    component: _ce6428e6,
    name: "user-documents___tr"
  }, {
    path: "/tr/user/passwordChange",
    component: _55f2c9b4,
    name: "user-passwordChange___tr"
  }, {
    path: "/tr/user/requestsandoffers",
    component: _79dd6dbf,
    name: "user-requestsandoffers___tr"
  }, {
    path: "/en/announcements/:slug",
    component: _a1808c04,
    name: "announcements-slug___en"
  }, {
    path: "/en/articles/:slug",
    component: _a05f7d5e,
    name: "articles-slug___en"
  }, {
    path: "/en/news/:slug",
    component: _13bc9ee7,
    name: "news-slug___en"
  }, {
    path: "/en/products/:slug",
    component: _04c08890,
    name: "products-slug___en"
  }, {
    path: "/en/vacancies/:slug",
    component: _2d39e38b,
    name: "vacancies-slug___en"
  }, {
    path: "/ru/announcements/:slug",
    component: _a1808c04,
    name: "announcements-slug___ru"
  }, {
    path: "/ru/articles/:slug",
    component: _a05f7d5e,
    name: "articles-slug___ru"
  }, {
    path: "/ru/news/:slug",
    component: _13bc9ee7,
    name: "news-slug___ru"
  }, {
    path: "/ru/products/:slug",
    component: _04c08890,
    name: "products-slug___ru"
  }, {
    path: "/ru/vacancies/:slug",
    component: _2d39e38b,
    name: "vacancies-slug___ru"
  }, {
    path: "/tr/announcements/:slug",
    component: _a1808c04,
    name: "announcements-slug___tr"
  }, {
    path: "/tr/articles/:slug",
    component: _a05f7d5e,
    name: "articles-slug___tr"
  }, {
    path: "/tr/news/:slug",
    component: _13bc9ee7,
    name: "news-slug___tr"
  }, {
    path: "/tr/products/:slug",
    component: _04c08890,
    name: "products-slug___tr"
  }, {
    path: "/tr/vacancies/:slug",
    component: _2d39e38b,
    name: "vacancies-slug___tr"
  }, {
    path: "/announcements/:slug",
    component: _a1808c04,
    name: "announcements-slug___az"
  }, {
    path: "/articles/:slug",
    component: _a05f7d5e,
    name: "articles-slug___az"
  }, {
    path: "/news/:slug",
    component: _13bc9ee7,
    name: "news-slug___az"
  }, {
    path: "/products/:slug",
    component: _04c08890,
    name: "products-slug___az"
  }, {
    path: "/vacancies/:slug",
    component: _2d39e38b,
    name: "vacancies-slug___az"
  }, {
    path: "/",
    component: _516b2bc9,
    name: "index___az"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
