
  import IconCalendar from '~/assets/icons/calendar.svg?inline';
  export default {
    components: {
      IconCalendar
    },
    data: () => ({
      debounce: null,
      query: null,
      result: null,
      navigation: null,

    }),
    watch: {
      query() {
        this.debounceSearch();
      }
    },
    mounted() {
      this.navigation = this.$store.state.navigation.items;
    },
    methods: {
      sendSearchReq() {
        this.$nuxt.$emit('updateSerachResult');

      },
      async search() {
        try {
          const currentLocale = this.$nuxt.context.app.i18n.locales.filter((lang) => lang.code === this.$nuxt.context
            .app.i18n.locale)[0];

          const response = await this.$axios.$get('/search/' + currentLocale.code + '/' + this.query);
          if (response.status != 'success' || response.code != 200) {
            console.error('Server error');
            return;
          };
          this.result = response;
        } catch (e) {
          console.error(`Error ${e}`);
        }

      },
      debounceSearch() {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.search()
        }, 300)
      },
    }
  }

