
  import IconChevron from '~/assets/icons/chevron-right.svg?inline';
  import IconInstagram from '~/assets/icons/instagram.svg?inline';
  import IconFacebook from '~/assets/icons/facebook.svg?inline';
  import IconLinkedin from '~/assets/icons/linkedin.svg?inline';
  import IconYoutube from '~/assets/icons/youtube.svg?inline';
  import IconCall from '~/assets/icons/call.svg?inline';
  import IconUser from '~/assets/icons/user.svg?inline';
  import IconLogout from '~/assets/icons/logout.svg?inline';
  export default {
    components: {
      IconChevron,
      IconInstagram,
      IconFacebook,
      IconLinkedin,
      IconYoutube,
      IconCall,
      IconUser,
      IconLogout,
 
    },
    data: () => ({
      openNavigation: false,
      limitPosition: 100,
      scrolled: false,
      lastPosition: 0,
           isOpen: false,
    }),
    methods: {
      handleScroll() {
        if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
          this.scrolled = true;
          // move up!
        }

        if (this.lastPosition > window.scrollY) {
          this.scrolled = false;
          // move down
        }

        this.lastPosition = window.scrollY;
        // this.scrolled = window.scrollY > 250;
      }
    },
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    }
  };

