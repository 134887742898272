import Vue from 'vue';
const globals ={
    api: {
      host: "https://admin.dtf.gov.az",
    },
    domain: "https://dtf.gov.az",
    importImage(path) {
      return this.api.host + path;
    },
    getFileUrl(path) {
      return this.api.host + '/storage/' + path;
    },
    getUrl(path) {
      return this.api.host + path;
    },
    getDomain(path) {
      return this.domain + path;
    }
}

export default ({app}, inject) => {
  inject('globals', Vue.observable(globals));
}

