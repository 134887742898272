export default function ({
  app,
  store
}) {
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    const nuxt = store._vm.$nuxt;
      if (nuxt) {
        nuxt.$emit('lang-changed', newLocale);
      }
  }
}
