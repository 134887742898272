import Vue from 'vue';
export default function ({
  app,
  store
}) {

  Vue.directive('translate', {
    bind(el, binding, node) {
      const value = binding.value;
      const attribute = binding.arg;
      if (value && value.hasOwnProperty('translations') && value.translations.length > 0) {
        const translations = value.translations.filter(item => item.locale == app.i18n.locale);
        if (translations.length) {
          const column = translations.find(item => item.column_name == attribute);

          if (column) {
            el.innerHTML = column.value;
            // localStorage.setItem('translation', column.value);
          }
        }
      }
    }
  }); 

  Vue.directive('translateCompList', {
    bind(el, binding, node) {
      // const attribute = binding.arg;
      try {
        const value = JSON.parse(binding);
        el.innerHTML = value[app.i18n.locale];
      } catch (e) {
        el.innerHTML = ''
      }
    }
  });
  Vue.directive('translateComp', {
    bind(el, binding, node) {
      function checkJson(data) {
        try {
          JSON.parse(data);
          return true;
        }catch(e) {
          return false;
        }
      }
      try {
        const value = checkJson(binding.value) ? JSON.parse(binding.value) : binding.value;
        el.innerHTML = value[app.i18n.locale];
        el.dataset.href = value[app.i18n.locale];
      } catch (e) {
        el.innerHTML = ''
      }
    }
  });

  Vue.directive('translateSingle', {
    bind(el, binding, node) {
      // const attribute = binding.arg;
      try {

        const value = binding.value;
        el.innerHTML = value.value[app.i18n.locale];
      } catch (e) {
        el.innerHTML = 'ss'
      }
    }
  });
  Vue.directive('translateList', {
    bind(el, binding, node) {
      // const attribute = binding.arg;
      try {

        const value = binding.value;
        const key = binding.arg;
        let res = value.value[app.i18n.locale];
        res = res.split(":");
        el.innerHTML = key == 'key' ? res[0] : res[1];

      } catch (e) {
        el.innerHTML = ''
      }
    }
  });
}
