export const AzMap = () => import('../../components/common/AzMap.vue' /* webpackChunkName: "components/az-map" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/common/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../components/common/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/common/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/common/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderPrivate = () => import('../../components/common/HeaderPrivate.vue' /* webpackChunkName: "components/header-private" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/common/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MobileNavigation = () => import('../../components/common/MobileNavigation.vue' /* webpackChunkName: "components/mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/common/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const SearchModal = () => import('../../components/common/SearchModal.vue' /* webpackChunkName: "components/search-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductModal = () => import('../../components/common/productModal.vue' /* webpackChunkName: "components/product-modal" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/sections/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const HomeAbout = () => import('../../components/sections/HomeAbout.vue' /* webpackChunkName: "components/home-about" */).then(c => wrapFunctional(c.default || c))
export const HomeActivities = () => import('../../components/sections/HomeActivities.vue' /* webpackChunkName: "components/home-activities" */).then(c => wrapFunctional(c.default || c))
export const HomeNews = () => import('../../components/sections/HomeNews.vue' /* webpackChunkName: "components/home-news" */).then(c => wrapFunctional(c.default || c))
export const HomeProducts = () => import('../../components/sections/HomeProducts.vue' /* webpackChunkName: "components/home-products" */).then(c => wrapFunctional(c.default || c))
export const HomeServices = () => import('../../components/sections/HomeServices.vue' /* webpackChunkName: "components/home-services" */).then(c => wrapFunctional(c.default || c))
export const MainGoal = () => import('../../components/sections/MainGoal.vue' /* webpackChunkName: "components/main-goal" */).then(c => wrapFunctional(c.default || c))
export const Partners = () => import('../../components/sections/Partners.vue' /* webpackChunkName: "components/partners" */).then(c => wrapFunctional(c.default || c))
export const Statistics = () => import('../../components/sections/Statistics.vue' /* webpackChunkName: "components/statistics" */).then(c => wrapFunctional(c.default || c))
export const UsefulLinks = () => import('../../components/sections/UsefulLinks.vue' /* webpackChunkName: "components/useful-links" */).then(c => wrapFunctional(c.default || c))
export const SimilarProducts = () => import('../../components/sections/similarProducts.vue' /* webpackChunkName: "components/similar-products" */).then(c => wrapFunctional(c.default || c))
export const OfferAddModal = () => import('../../components/user/offerAddModal.vue' /* webpackChunkName: "components/offer-add-modal" */).then(c => wrapFunctional(c.default || c))
export const RequestAddModal = () => import('../../components/user/requestAddModal.vue' /* webpackChunkName: "components/request-add-modal" */).then(c => wrapFunctional(c.default || c))
export const SideMenu = () => import('../../components/user/sideMenu.vue' /* webpackChunkName: "components/side-menu" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
