
  import IconChevron from "~/assets/icons/chevron-right.svg?inline";
  import {mapState} from 'vuex';
  export default {
    components: {
      IconChevron,
    },
    data: () => ({
      currentLocale: null,
      currentCode: null,

    }),
    created() {
      console.log(this.$nuxt.$route.path.replace(/(\/en|\/az|\/ru|\/tr)/, '').replace(/\/$/, ""))
    },
    mounted() {
          this.$nuxt.$on('lang-changed', () => {
    })
    },
    computed: {
      nav() {
        console.log("UPDATED", this.$store.state.navigation.items);
        return this.$store.state.navigation.items;
      },
      currentRoute() {
        if(this.$store.state.navigation.items){

          return this.$store.state.navigation.items.find(item => item.children.find(child => child.url == this.$nuxt.$route.path.replace(/(\/en|\/az|\/ru|\/tr)/, '').replace(/\/$/, "")) ? item : null);

        }
      
      return null;
      }
    },
  };

