import { render, staticRenderFns } from "./HeaderPrivate.vue?vue&type=template&id=1a61203a&scoped=true&"
import script from "./HeaderPrivate.vue?vue&type=script&lang=js&"
export * from "./HeaderPrivate.vue?vue&type=script&lang=js&"
import style0 from "./HeaderPrivate.vue?vue&type=style&index=0&id=1a61203a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a61203a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/base/1146/public_html/components/common/Logo.vue').default,Header: require('/base/1146/public_html/components/common/Header.vue').default})
