import Vue from 'vue';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import flatpickr from "flatpickr";
import { Azerbaijan } from "flatpickr/dist/l10n/az.js";
flatpickr.setDefaults({
  locale: Azerbaijan
});
import VueFlatPickr from 'vue-flatpickr-component';

Vue.use(VueFlatPickr);