
export default {
  scrollToTop: true,
      data: () => ({
      isActive: false,
      settings: null,
            lang: 'az',
    }),
        head() {
      return {
        htmlAttrs: {
          lang: this.lang,
        }
      }
    },
    async fetch() {
      const response = await fetch(this.$store.$globals.getUrl("/api/settings")).then(
        (res) => res.json()
      );
      this.settings = response.settings;
      this.saveSettings();
    },
    mounted() {
              this.$nuxt.$on('lang-changed', () => {

        const currentLocale = this.$i18n.locales.filter((lang) => lang.code === this.$i18n.locale)[0];
        this.lang = currentLocale.code;
      })
    },
    methods: {
      saveSettings() {
        this.$store.commit("settings/save", this.settings);
      },
            getValue(row, name) {
        const data = row.find(item => item.name == name);
        if (data) {
          return data.hasOwnProperty('value') ? data.value : '';
        }
        return '';
      },
      getValue(row, name) {
        const data = row.find(item => item.name == name);
        if (data) {
          return data.hasOwnProperty('value') ? data.value : '';
        }
        return '';
      },

      getArrayValue(row, name, key) {
        const arr = this.getValue(row, name);
        const data = arr.find(item => item.key == key);

        if (data) {
          return data.hasOwnProperty('value') ? data.value : '';
        }
        return '';
      }

    },
}
