import Vue from 'vue';
export const state = () => {
    items: []
}

export const mutations = {
  save(state, nav) {
    // state.items = nav; 
    Vue.set(state, 'items', nav);
    }
}