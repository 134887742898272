export default function ({ $axios, store }) {
  $axios.onRequest(config => {
    store._vm.$nextTick(() => {
      if(store._vm.$nuxt){
      store._vm.$nuxt.$loading.start()
      return config;
      }
    });
  }),

  $axios.onResponse((response) => {
    store._vm.$nextTick(() => {
      if(store._vm.$nuxt){
      store._vm.$nuxt.$loading.finish()
      return response
      }
    });
  })

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    store._vm.$nextTick(() => {
      if(store._vm.$nuxt){
      store._vm.$nuxt.$loading.finish()
      return Promise.reject(error)
      }
    });
    if (code === 400) {
      redirect('/400')
    }
  })
}