import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize, configure  } from 'vee-validate';
import { required, min, email } from "vee-validate/dist/rules";
import { i18n } from "./i18n";
Vue.component('ValidationProvider', ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
export default function ({
  app,
  store
}) {
  configure({
    // this will be used to generate messages.
    defaultMessage: (field, values) => {
    i18n.locale = app.i18n.locale;
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values);
  }
});
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: () => {
    i18n.locale = app.i18n.locale;
    return i18n.t('customValidations.confirm')
  }
  }
);
}



extend("required", required);

extend("email", email);

extend("min", min); 

